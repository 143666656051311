$white: #ffffff;
$light-white: rgba(255, 255, 255, 0.7);
$main-text: #31343d;
$black-text: #3b3b3b;
$blue: #52a5f6;
$light-blue: #51b6f5;
$super-light-grey: #919ca9;
$grey: #d4d7d8;
$grey-icon: #97a2ae;
$dark-grey: #949eab;
$light-grey: #f0f1f2;
$label-text: #89929a;
$super-lightest-grey: #81888e;
$light-grey-blue: #c2d4e3;
$grey-red: #d36746;
$bright-blue: #5dc8fb;
$bright-light-blue: #5fdbfd;
$box-shadow: rgba(211, 214, 219, 0.48);
$btn-box-shadow: rgba(111, 196, 249, 0.35);
$fon-background: rgba(49, 52, 61, 0.8);
$toastr-background: #1f232e;
$toastr-return: #a5a9b2;
$super-red: #e15342;
$super-green: #68ab7b;
$tooltip-background: rgba(8, 14, 33, 0.8);;
$scroll: #dae5ee;
$scroll-active: linear-gradient(to right, #52a5f6, #5ac8fb);
$scroll-menu: #a1d7f9;
$scroll-menu-active: #c7e6f9;
$select-border: #b8d2e2;
$select-light-border: #dee2ea;
$select-box-shadow: rgba(194, 212, 227, 0.75);
$select-background: #edeff0;
$tab-box-shadow: rgba(102, 121, 140, 0.5);
$input-disabled: #e1e5e6;
$search-field-color: #8e9199;
$filter-box-shadow: #dce0e6;
$filter-border: #ebebeb;
$dictionary-border: #d7dfe6;
$month-disabled: #cbcbcb;
$calendar-grey: #878d95;
$calendar-black: #333741;
$calendar-text: #31343c;
$calendar-light-grey: #cccfd3;
$grid-header-border: #dadce0;
$grid-row-select: #e9f5fb;
$settings-box-shadow: #d2dce6;
$collapse-color: rgba(149, 160, 172, 0.98);
$str_color: #95a0ac;
$check-readonly: #86c0f9;
$no-acces-stub: #c4c8cf;
$context-menu-shadow: rgba(32, 72, 106, 0.25);
$light-grey-border: #f2f2f2;

@import './variables';
@import './mixin-grid';

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  height: 100vh;
  width: 100%;
  min-width: 1280px;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  background-color: $light-grey;
  overflow: hidden;
}

app-root {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex: 1;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

//класс основного контента страницы
.page-content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: calc(100vh - 61px);
  flex: 1;
  overflow: hidden;
}

// ссылки - внутренняя и внешняя
a {
  font-size: 15px;
  line-height: 1.23;
  letter-spacing: -0.08px;
  color: $blue;
  font-family: 'Roboto', sans-serif;
  text-decoration: none;

  &.inner-href {
    border-bottom: 1px dashed transparent;
  }

  &.outer-href {
    border-bottom: 1px solid transparent;
  }

  &:hover {
    &.inner-href {
      border-bottom: 1px dashed $blue;
    }

    &.outer-href {
      border-bottom: 1px solid $blue;
    }
  }
}

// модальное окно
.modal-backdrop{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: $fon-background;
  opacity: 1;
  z-index: 100!important;
}
.modal{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2000;
  outline: 0;

  .modal-dialog{
    font-family: 'Roboto', sans-serif;
    position: fixed;
    width: auto;
    height: auto;
    top: 70px;
    left: calc(50%);
    transform: translate(-50%, 0%);
    max-width: 800px;
    min-width: 200px;
    max-height: calc(100% - 140px);
    display: block;
    border-radius: 8px;
    background: $white;
    overflow: hidden;
    outline: none;

    &.modal-dialog-centered{
      top: calc(50%);
      transform: translate(-50%, -50%);
    }

    .modal-content {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: flex-start;
      width: 100%;
      height: 100%;
    }

    .modal-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: auto;
      padding: 32px 36px 20px;
      border-bottom: solid 1px $light-grey-blue;
      font-size: 20px;
      line-height: 1.23;
      letter-spacing: -0.2px;
      color: $black-text;
      box-sizing: border-box;
    }

    .modal-body {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      width: 100%;
      height: auto;
      box-sizing: border-box;
    }

    .modal-footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      height: auto;
      min-height: 51px;
      padding: 6px 14px 8px;
      border-top: solid 1px $light-grey-blue;
      box-sizing: border-box;

      .modal-footer-btn {
        margin: 0 6px;
      }
    }
  }

  &.modal-confirm {
    .modal-dialog-centered{
      min-width: 375px;
      max-width: 650px;
    }
  }

  &.modal-standart {
    .modal-dialog-centered{
      min-width: 413px;
    }
  }

  &.dictionary-modal {
    .modal-dialog-centered{
      min-width: 441px;
      max-width: 441px;
      height: 620px;
    }

    &.dictionary-modal-full-width {
      .modal-dialog-centered {
        min-width: 681px;
        max-width: 681px;
        height: 770px;
        max-height: calc(100% - 80px);
      }
    }
  }

  &.password-modal {
    .modal-dialog-centered{
      min-height: 512px;
    }
  }

  &.modal-full-card {
    .modal-dialog{
      top: 30px;
      bottom: 30px;
      left: 25px;
      transform: none;
      min-width: calc(100vw - 50px);
      max-width: calc(100vw - 50px);
      min-height: calc(100vh - 60px);
      max-height: calc(100vh - 60px);
    }
  }

  &.modal-universal-card {
    .modal-dialog{
      max-width: calc(100vw - 120px);
      max-height: calc(100vh - 120px);
      min-height: 400px;
    }
  }

  &.modal-extend-filter {
    .modal-dialog{
      max-width: 880px;
      min-width: 880px;
      width: 880px;
      height: 550px;
      max-height: 550px;
      min-height: 550px;
      overflow: visible;
    }
  }

}

// тултип
.tooltip {
  &.grid-header-tooltip {
    max-width: 250px;
  }

  &.card-field-tooltip {
    max-width: 270px;
  }

  &.action-menu-tooltip {
    width: 238px;
    max-width: 238px;
    padding: 28px 31px;
    border-radius: 8px;
    box-shadow: 0 1px 5px 0 rgba(32, 72, 106, 0.25);
    border: solid 1px $select-border;
    background-color: $white;
    margin-right: -6px;
    margin-top: 15px;
  }

  &.tree-state-tooltip {
    max-width: 210px;
    padding: 25px 27px;
    margin-left: -30px;

    .tooltip-inner {
      line-height: 1.41;
    }
  }
}


// наименование поля в форме
.form-field-label {
  display: inline-flex;
  font-size: 15px;
  line-height: 1.07;
  color: $label-text;

  &.required {
    position: relative;

    &:after {
      content: '*';
      font-family: 'Roboto', sans-serif;
      position: relative;
      right: -3px;
      top: -3px;
      font-size: 18px;
      color: $blue;
    }
  }
}

.form-field-error {
  width: 100%;
  padding: 5px 10px 0;
  font-size: 12px;
  color: $grey-red;
}

.tabs-content {
  width: 100%;
  margin-top: 20px;
}

.filter-panel-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 0 0 0 16px;
  border-bottom: solid 1px $filter-border;

  &:last-child {
    border-bottom: none;
  }

  .filter-panel-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    min-height: 41px;
    padding: 5px 0 5px 0;
    border-bottom: solid 1px $filter-border;

    &:last-child {
      border-bottom: none;
    }
  }

  .filter-panel-item-search {
    display: block;
    width: 100%;
    margin-bottom: 14px;
  }
}

.action-menu-item {
  display: inline-block;
  font-size: 15px;
  line-height: normal;
  letter-spacing: -0.08px;
  color: $main-text;
  cursor: pointer;
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    color: $blue;
  }

  &.disabled {
    cursor: default;
    opacity: 0.6;

    &:hover {
      color: $main-text;
    }
  }
}

.grid-context-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 275px;
  max-height: 333px;
  padding: 28px 11px 28px 32px;
  border-radius: 8px;
  box-shadow: 0 1px 5px 0 $context-menu-shadow;
  border: solid 1px $select-border;
  background-color: $white;
  overflow: hidden;

  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    max-height: 100%;
    margin: 0;
    padding: 0 14px 0 0;
    list-style: none;
    overflow-x: hidden;
    overflow-y: auto;

    li {
      display: block;
      margin-bottom: 21px;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        font-size: 15px;
        font-weight: normal;
        line-height: normal;
        letter-spacing: -0.08px;
        color: $main-text;
        cursor: pointer;

        &:hover {
          color: $blue;
        }
      }
    }
  }
}

.scroll-block,
.ngx-contextmenu .dropdown-menu {
  scrollbar-color: $scroll transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar{
    width: 10px !important;
    height: 10px !important;
  }

  &::-webkit-scrollbar-track{
    //box-shadow: inset 0 0 5px grey !important;
    border-radius: 0px !important;
  }

  &::-webkit-scrollbar-thumb{
    background: $scroll !important;
    border-radius: 5px !important;
    cursor: pointer !important;
  }

  &::-webkit-scrollbar-thumb:hover{
    background: $scroll-active !important;
    cursor: pointer !important;
  }
}

.scroll-menu-block {
  scrollbar-color: $scroll-menu transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar{
    width: 10px !important;
    height: 10px !important;
  }

  &::-webkit-scrollbar-track{
    //box-shadow: inset 0 0 5px grey !important;
    border-radius: 0px !important;
  }

  &::-webkit-scrollbar-thumb{
    background: $scroll-menu !important;
    border-radius: 5px !important;
    cursor: pointer !important;
  }

  &::-webkit-scrollbar-thumb:hover{
    background: $scroll-menu-active !important;
    cursor: pointer !important;
  }
}

.ag-body-vertical-scroll-viewport {
  scrollbar-color: $scroll transparent;
  scrollbar-width: thin;

  &::-webkit-scrollbar{
    width: 10px !important;
    height: 10px !important;
  }

  &::-webkit-scrollbar-track{
    //box-shadow: inset 0 0 5px grey !important;
    border-radius: 0px !important;
  }

  &::-webkit-scrollbar-thumb{
    background: $scroll !important;
    border-radius: 5px !important;
    cursor: pointer !important;
  }

  &::-webkit-scrollbar-thumb:hover{
    background: $scroll-active !important;
    cursor: pointer !important;
  }
}

@font-face {
  font-family: "font-icons";
  src: url("../assets/fonts/FontIcons/font-icons.eot?2b4hmc");
  src: url("../assets/fonts/FontIcons/font-icons.eot?2b4hmc#iefix") format("embedded-opentype"),
    url("../assets/fonts/FontIcons/font-icons.ttf?2b4hmc") format("truetype"),
    url("../assets/fonts/FontIcons/font-icons.woff?2b4hmc") format("woff"),
    url("../assets/fonts/FontIcons/font-icons.svg?2b4hmc#asupr_v1") format("svg");
  font-weight: normal;
  font-style: normal;
}

.font-icons {
  font-family: "font-icons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: inherit;
  display: inline-block;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-back:before {
  content: "\e900";
  color: #52a5f6;
}
.icon-forward:before {
  content: "\e901";
  color: #a5a9b2;
}
.icon-ok:before {
  content: "\e902";
  color: #52a5f6;
}
.icon-logo:before {
  content: "\e903";
  color: #52a5f6;
}
.icon-mail:before {
  content: "\e904";
  color: #52a5f6;
}
.icon-phone:before {
  content: "\e905";
  color: #52a5f6;
}
.icon-unlock:before {
  content: "\e906";
  color: #52a5f6;
}
.icon-error:before {
  content: "\e907";
  color: #d36746;
}

.icon-download:before {
  content: "\e908";
  color: #52a5f6;
}
.icon-show:before {
  content: "\e909";
  color: #52a5f6;
}
.icon-hide:before {
  content: "\e90a";
  color: #a5a9b2;
}
.icon-notification_with_round .path1:before {
  content: "\e90b";
  color: rgb(145, 156, 169);
}
.icon-notification_with_round .path2:before {
  content: "\e90c";
  margin-left: -0.99609375em;
  color: rgb(225, 83, 66);
}
.icon-mini_logo:before {
  content: "\e90d";
  color: #52a5f6;
}
.icon-close:before {
  content: "\e90e";
  color: #fff;
}
.icon-menu:before {
  content: "\e90f";
  color: #fff;
}
.icon-exclam_point:before {
  content: "\e910";
  color: #52a6f6;
}
.icon-support_info:before {
  content: "\e911";
  color: #919ca9;
}
.icon-menu_immac:before {
  content: "\e912";
  color: #fff;
}
.icon-arrow_top:before {
  content: "\e913";
  color: #95a0ac;
}
.icon-arrow_down:before {
  content: "\e914";
  color: #95a0ac;
}
.icon-menu_admin:before {
  content: "\e915";
  color: #fff;
}
.icon-menu_nsi:before {
  content: "\e916";
  color: #fff;
}
.icon-menu_analitic:before {
  content: "\e917";
  color: #fff;
}
.icon-menu_toir:before {
  content: "\e918";
  color: #fff;
}
.icon-menu_dispatcher:before {
  content: "\e919";
  color: #fff;
}
.icon-menu_commac:before {
  content: "\e91a";
  color: #fff;
}
.icon-menu_open:before {
  content: "\e91b";
  color: #fff;
}
.icon-menu_collapse:before {
  content: "\e91c";
  color: #fff;
}
.icon-str_down:before {
  content: "\e91d";
  color: #fff;
}
.icon-user:before {
  content: "\e91e";
  color: #52a5f6;
}
.icon-support:before {
  content: "\e91f";
  color: #52a5f6;
}
.icon-guide:before {
  content: "\e920";
  color: #52a5f6;
}
.icon-no_access:before {
  content: "\e921";
  color: #52a5f6;
}
.icon-user_logo:before {
  content: "\e922";
  color: #919ca9;
}
.icon-info:before {
  content: "\e923";
  color: #52a5f6;
}
.icon-forward-long:before {
  content: "\e924";
  color: #bec2cc;
}
.icon-notification:before {
  content: "\e925";
  color: #919ca9;
}

.icon-clear:before {
  content: "\e926";
  color: #919ca9;
}
.icon-heat_energy:before {
  content: "\e927";
  color: #e0633d;
}
.icon-switch_off:before {
  content: "\e928";
  color: #919ca9;
}
.icon-str_right:before {
  content: "\e929";
  color: #919ca9;
}
.icon-str_left:before {
  content: "\e92a";
  color: #919ca9;
}
.icon-sort_asc:before {
  content: "\e92b";
  color: #919ca9;
}
.icon-sort_desc:before {
  content: "\e92c";
  color: #919ca9;
}
.icon-search:before {
  content: "\e92d";
  color: #919ca9;
}
.icon-vent:before {
  content: "\e92e";
  color: #8ce5fb;
}
.icon-co:before {
  content: "\e92f";
  color: #facd61;
}
.icon-electro:before {
  content: "\e930";
  color: #ffa734;
}
.icon-menu_collapse_grey:before {
  content: "\e931";
  color: #95a0ac;
}
.icon-menu_open_grey:before {
  content: "\e932";
  color: #95a0ac;
}
.icon-check_tab:before {
  content: "\e933";
  color: #52a5f6;
}
.icon-plus:before {
  content: "\e934";
  color: #919ca9;
}
.icon-hvs:before {
  content: "\e935";
  color: #78c7e4;
}
.icon-gvs:before {
  content: "\e936";
  color: #f18d4a;
}
.icon-sort_grid_desc .path1:before {
  content: "\e937";
  color: rgb(149, 160, 172);
}
.icon-sort_grid_desc .path2:before {
  content: "\e938";
  margin-left: -0.587890625em;
  color: rgb(82, 165, 246);
}
.icon-sort_grid_asc .path1:before {
  content: "\e939";
  color: rgb(149, 160, 172);
}
.icon-sort_grid_asc .path2:before {
  content: "\e93a";
  margin-left: -0.587890625em;
  color: rgb(82, 165, 246);
}
.icon-switch_on .path1:before {
  content: "\e93b";
  color: rgb(82, 165, 246);
}
.icon-switch_on .path2:before {
  content: "\e93c";
  margin-left: -1.4990234375em;
  color: rgb(255, 255, 255);
}
.icon-check .path1:before {
  content: "\e93d";
  color: rgb(82, 165, 246);
}
.icon-check .path2:before {
  content: "\e93e";
  margin-left: -0.998046875em;
  color: rgb(255, 255, 255);
}
.icon-filter:before {
  content: "\e93f";
  color: #919ca9;
}
.icon-close_2:before {
  content: "\e940";
  color: #919ca9;
}
.icon-check_not_full:before {
  content: "\e941";
  color: #52a5f6;
}
.icon-lock:before {
  content: "\e942";
  color: #919ca9;
}
.icon-radio_check:before {
  content: "\e943";
  color: #52a5f6;
}
.icon-radio_nocheck:before {
  content: "\e944";
  color: #52a5f6;
}
.icon-support_header:before {
  content: "\e945";
  color: #919ca9;
}
.icon-guide_header:before {
  content: "\e946";
  color: #919ca9;
}

.icon-error_info .path1:before {
  content: "\e947";
  color: rgb(211, 103, 70);
}
.icon-error_info .path2:before {
  content: "\e948";
  margin-left: -1em;
  color: rgb(240, 241, 242);
}
.icon-select_point:before {
  content: "\e949";
  color: #c4c8cf;
}
.icon-calendar_date:before {
  content: "\e94a";
  color: #919ca9;
}
.icon-calendar_clear:before {
  content: "\e94b";
  color: #919ca9;
}
.icon-calendar_time:before {
  content: "\e94c";
  color: #919ca9;
}
.icon-calendar_date_checked .path1:before {
  content: "\e94d";
  color: rgb(221, 235, 248);
  opacity: 0.8;
}
.icon-calendar_date_checked .path2:before {
  content: "\e94e";
  margin-left: -1.2802734375em;
  color: rgb(192, 220, 247);
  opacity: 0.8;
}
.icon-calendar_date_checked .path3:before {
  content: "\e94f";
  margin-left: -1.2802734375em;
  color: rgb(151, 197, 242);
  opacity: 0.8;
}
.icon-calendar_date_checked .path4:before {
  content: "\e950";
  margin-left: -1.2802734375em;
  color: rgb(136, 188, 239);
  opacity: 0.8;
}
.icon-calendar_date_end_checked .path1:before {
  content: "\e951";
  color: rgb(221, 235, 248);
  opacity: 0.8;
}
.icon-calendar_date_end_checked .path2:before {
  content: "\e952";
  margin-left: -1.3896484375em;
  color: rgb(192, 220, 247);
  opacity: 0.8;
}
.icon-calendar_date_end_checked .path3:before {
  content: "\e953";
  margin-left: -1.3896484375em;
  color: rgb(161, 203, 244);
  opacity: 0.8;
}
.icon-calendar_date_end_checked .path4:before {
  content: "\e954";
  margin-left: -1.3896484375em;
  color: rgb(136, 188, 239);
  opacity: 0.8;
}
.icon-calendar_date_start_checked .path1:before {
  content: "\e955";
  color: rgb(221, 235, 248);
  opacity: 0.8;
}
.icon-calendar_date_start_checked .path2:before {
  content: "\e956";
  margin-left: -1.390625em;
  color: rgb(192, 220, 247);
  opacity: 0.8;
}
.icon-calendar_date_start_checked .path3:before {
  content: "\e957";
  margin-left: -1.390625em;
  color: rgb(161, 203, 244);
  opacity: 0.8;
}
.icon-calendar_date_start_checked .path4:before {
  content: "\e958";
  margin-left: -1.390625em;
  color: rgb(136, 188, 239);
  opacity: 0.8;
}

.icon-marker:before {
  content: "\e959";
  color: #fff;
}
.icon-delete:before {
  content: "\e95a";
  color: #919ca9;
}
.icon-select_grid_row:before {
  content: "\e95b";
  color: #52a5f6;
}
.icon-replication:before {
  content: "\e95c";
  color: #919ca9;
}
.icon-gaz:before {
  content: "\e95d";
  color: #8c94f2;
}

.icon-tree_unblocked:before {
  content: "\e95e";
  color: #52a5f6;
}
.icon-tree_blocked:before {
  content: "\e95f";
  color: #919ca9;
}
.icon-filter_open:before {
  content: "\e960";
  color: #919ca9;
}
.icon-filter_close:before {
  content: "\e961";
  color: #919ca9;
}

.icon-filter_open_has .path1:before {
  content: "\e962";
  color: rgb(145, 156, 169);
}
.icon-filter_open_has .path2:before {
  content: "\e963";
  margin-left: -1.0322265625em;
  color: rgb(82, 165, 246);
}
.icon-filter_close_has .path1:before {
  content: "\e964";
  color: rgb(145, 156, 169);
}
.icon-filter_close_has .path2:before {
  content: "\e965";
  margin-left: -1.0322265625em;
  color: rgb(82, 165, 246);
}
.icon-filter_has_hover:before {
  content: "\e966";
  color: #52a5f6;
}
.icon-filter_has .path1:before {
  content: "\e967";
  color: rgb(145, 156, 169);
}
.icon-filter_has .path2:before {
  content: "\e968";
  margin-left: -1.10546875em;
  color: rgb(82, 165, 246);
}
.icon-boolean-false .path1:before {
  content: "\e969";
  color: rgb(218, 68, 68);
}
.icon-boolean-false .path2:before {
  content: "\e96a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-boolean-true .path1:before {
  content: "\e96b";
  color: rgb(102, 183, 49);
}
.icon-boolean-true .path2:before {
  content: "\e96c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-arhive_version:before {
  content: "\e96d";
  color: #999;
}
.icon-current_version:before {
  content: "\e96e";
  color: #66b731;
}
.icon-future_version:before {
  content: "\e96f";
  color: #ebae2b;
}
.icon-action_menu:before {
  content: "\e970";
  color: #919ca9;
}
.icon-edit_row:before {
  content: "\e971";
  color: #919ca9;
}
.icon-refresh:before {
  content: "\e972";
  color: #919ca9;
}
.icon-change_view_grid:before {
  content: "\e973";
  color: #919ca9;
}
.icon-upload_file:before {
  content: "\e974";
  color: #919ca9;
}
.icon-close_tree:before {
  content: "\e975";
  color: #919ca9;
}
.icon-select_grid_row_2:before {
  content: "\e976";
  color: #52a5f6;
}
.icon-context_menu:before {
  content: "\e977";
  color: #919ca9;
}
.icon-place_point:before {
  content: "\e978";
  color: #919ca9;
}
.icon-crash:before {
  content: "\e979";
  color: #e13c40;
}
.icon-open_tree:before {
  content: "\e97a";
  color: #919ca9;
}
.icon-pc:before {
  content: "\e97b";
  color: #e13c40;
}
.icon-no_favorite:before {
  content: "\e97c";
  color: #919ca9;
}
.icon-favorite:before {
  content: "\e97d";
  color: #919ca9;
}
.icon-serial_number:before {
  content: "\e97e";
  color: #919ca9;
}
.icon-good:before {
  content: "\e97f";
  color: #66b731;
}
.icon-service:before {
  content: "\e980";
  color: #ebae2b;
}
.icon-not_work:before {
  content: "\e981";
  color: #da4444;
}
.icon-registered:before {
  content: "\e982";
  color: #9878c8;
}
.icon-on_check:before {
  content: "\e983";
  color: #7bc3b8;
}
.icon-on_approval:before {
  content: "\e984";
  color: #795645;
}
.icon-new:before {
  content: "\e985";
  color: #b7ca5b;
}
.icon-recalled:before {
  content: "\e986";
  color: #999;
}
.icon-verified:before {
  content: "\e987";
  color: #b7ca5b;
}
.icon-deleted:before {
  content: "\e988";
  color: #999;
}
.icon-not_connection:before {
  content: "\e989";
  color: #da4444;
}
.icon-completed:before {
  content: "\e98a";
  color: #7dc4b9;
}
.icon-font-icons_133:before {
  content: "\e98b";
  color: #999;
}
.icon-no_data:before {
  content: "\e98c";
  color: #da4444;
}
.icon-operated_without_uspd:before {
  content: "\e98d";
  color: #99649f;
}
.icon-connected_to_uspd:before {
  content: "\e98e";
  color: #5f6c9c;
}
.icon-operated_with_uspd:before {
  content: "\e98f";
  color: #7bc3b8;
}
.icon-in_stock:before {
  content: "\e990";
  color: #999;
}
.icon-denied:before {
  content: "\e991";
  color: #da4444;
}
.icon-on_completion:before {
  content: "\e992";
  color: #ebae2b;
}
.icon-in_archive:before {
  content: "\e993";
  color: #999;
}
.icon-archive_tu:before {
  content: "\e994";
  color: #97a2ae;
}
.icon-clearer:before {
  content: "\e995";
  color: #919ca9;
}
.icon-link:before {
  content: "\e996";
  color: #52a5f6;
}
.icon-report:before {
  content: "\e997";
  color: #919ca9;
}
.icon-comment:before {
  content: "\e999";
  color: #919ca9;
}
.icon-list_download:before {
  content: "\e998";
  color: #919ca9;
}
.icon-logo2_min:before {
  content: "\e99a";
  color: #52a5f6;
}
.icon-logo2:before {
  content: "\e99b";
  color: #52a5f6;
}
.icon-home:before {
  content: "\e99c";
  color: #52a5f6;
}
.icon-404:before {
  content: "\e99d";
  color: #52a5f6;
}
.icon-success_with_error .path1:before {
  content: "\e99e";
  color: rgb(235, 174, 43);
}
.icon-success_with_error .path2:before {
  content: "\e99f";
  margin-left: -0.99609375em;
  color: rgb(235, 174, 43);
}
.icon-success_with_error .path3:before {
  content: "\e9a0";
  margin-left: -0.99609375em;
  color: rgb(255, 255, 255);
}
.icon-play:before {
  content: "\e9a1";
  color: #a5a9b2;
}


